import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"forgot-password-page",attrs:{"fluid":"","pa-0":""}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"forgot-password-form-wrapper text-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/svg/logo-short.svg")}}),_c('p',{staticClass:"forgot-password-text"},[_vm._v("Forgot Password?")]),_c(VDivider),_c('p',{staticClass:"description"},[_vm._v("Enter the email address associated with your account.")]),_c(VForm,{staticClass:"forgot-password-form",on:{"submit":_vm.submitForm}},[_c('BaseInput',{attrs:{"solo":"","placeholder":"Email Address","name":"email","status":_vm.errors('email').length ? 'error': 'normal',"error-messages":_vm.errors('email')},on:{"input":function($event){return _vm.$v.email.$touch()},"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c(VBtn,{staticClass:"forgot-password-button btn-purple",attrs:{"type":"submit","block":"","disabled":_vm.$v.$dirty && _vm.$v.$invalid}},[_vm._v(" Send Reset Link ")])],1)],1)],1),_c(VRow,{staticClass:"ma-0 w-100"},[_c(VImg,{staticClass:"image-style",attrs:{"width":"100%","aspect-ratio":_vm.aspectRatio,"src":require("@/assets/svg/theme/common.svg")}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }