<template>
  <v-container
    fluid
    pa-0
    class="forgot-password-page"
  >
    <v-row class="ma-0">
      <v-col cols=12 class="forgot-password-form-wrapper text-center">
        <img src="@/assets/svg/logo-short.svg" class="logo" />
        <p class="forgot-password-text">Forgot Password?</p>
        <v-divider/>
        <p class="description">Enter the email address associated with your account.</p>
        <v-form class="forgot-password-form" @submit="submitForm">
          <BaseInput
            solo
            placeholder="Email Address"
            name="email"
            v-model.trim="email"
            :status="errors('email').length ? 'error': 'normal'"
            :error-messages="errors('email')"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
          <v-btn
            class="forgot-password-button btn-purple"
            type="submit"
            block
            :disabled="$v.$dirty && $v.$invalid"
          >
            Send Reset Link
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="ma-0 w-100">
            <v-img class="image-style"
            width="100%" :aspect-ratio="aspectRatio" src="@/assets/svg/theme/common.svg" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';

const namespace = 'user';
export default {
  name: 'ForgotPassword',
  components: { BaseInput },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'email':
            if (!this.$v.email.email) { errors.push('Please provide a valid email address'); }
            if (!this.$v.email.required) { errors.push('Please provide your email address'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    aspectRatio() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions(namespace, ['recoverUser']),
    togglePasswordDisplay() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await this.recoverUser({
          email: this.email,
        });
        if (response.success) {
          this.$router.push('/login');
        }
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
<style lang="scss" scoped>
.forgot-password-page {
  height: 100%;
  background: $neutral6;
  justify-content: space-between;
  max-height: 100vh;
}
.row {
  flex: 0;
}
.image-style{
  display:inline-block;
  margin-top:10px;
}
.forgot-password-form-wrapper {
  height: 100%;
  .v-divider {
    margin: 0 30%;
  }
}
.logo {
  margin: auto;
  padding-top: 4%;
  width: 86px;
}
.forgot-password-text {
  padding-top: 40px;
  font-size: 30px;
  font-weight: bold;
}
.description {
    font-size: 18px;
    margin-top: 15px;
}
.forgot-password-form {
  font-family: $fontFamily1;
  width: 300px;
  margin: auto;
  margin-top: 30px;
}
::v-deep .theme--light.v-label {
  color: $neutral1;
}
::v-deep .v-messages {
  font-size: 14px !important;
}
.forgot-password-button {
  margin-top: 20px;
  padding-bottom: 20px;
  ::v-deep .v-btn__content {
    font-size: 16px;
  }
}
.v-btn.v-size--default {
  height: 48px !important;
}
.vlogin-form .v-btn__content {
  font-size: 16px;
}
@media (max-width: 600px) {
  .logo {
    margin-top: 47px;
  }
  .v-divider {
    margin: 0 !important;
  }
}
::v-deep .v-input input::placeholder {
  font-size: 12px !important;
  color: $graySuite;
  letter-spacing: -0.12px;
}
</style>
